import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableSortLabel, TableHead, TableRow,
  Paper, TextField, Button, Box, Container, Select, MenuItem, InputLabel, FormControl,
  Typography, useMediaQuery, Skeleton, CssBaseline, ThemeProvider
} from '@mui/material';
import MainNav from '../../components/navigation/MainNav';
import { api } from '../../services/apis';
import { matchHeaders, mainTheme, formatCurrency } from '../../services/settings';
import * as XLSX from 'xlsx';

const ClubsBillingPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [billingSummary, setBillingSummary] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [selectedTenant, setSelectedTenant] = useState('');
  const [userBillingSummary, setUserBillingSummary] = useState([]);
  const [distinctTenants, setDistinctTenants] = useState([]); // Track tenants for filtering
  const [approvedStatus, setApprovedStatus] = useState(false);
  const [payoutStatus, setPayoutStatus] = useState(false);
  const [payoutHandlerLoading, setPayoutHandlerLoading]  = useState(false);
  const [approveHandlerLoading, setApproveHandlerLoading]  = useState(false);
  const userRole = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    fetchEOMdebits();
    }, []);

  useEffect(() => {
    fetchUserDebits();
  }, []);

  const fetchEOMdebits = async () => {
    try {
      const headers = await matchHeaders(localStorage.getItem('role'));
      const response = await axios.post(
        'https://minerva.sipora.io/api/reporting/eom-reporting-clubs',
        { tenantName: 'ROUNDA-STAGE' },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: headers.apikey,
            tenant: headers.appTenant,
            tenantA: headers.authTenant,
          },
        }
      );

      const billingData = response.data || [];
      setBillingSummary(billingData); // Set the data to state
      setDistinctTenants([...new Set(billingData.map(billing => billing.tenantName))]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching EOM debits:', error);
      setIsLoading(false);
    }
  };

  const fetchUserDebits = async () => {
    try {
      const headers = await matchHeaders(localStorage.getItem('role'));
      const response = await axios.post(
        'https://minerva.sipora.io/api/reporting/eom-reporting',
        { tenantName: 'ROUNDA-STAGE' },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: headers.apikey,
            tenant: headers.appTenant,
            tenantA: headers.authTenant,
          },
        }
      );

      const userBillingData = response.data || [];
      setUserBillingSummary(userBillingData); // Set the data to state
      } catch (error) {
      console.error('Error fetching EOM debits:', error);
       }
  };


  const exportToExcel = () => {
    const filteredDataForExport = billingSummary.map(billing => ({
      tenantName: billing.tenantName,
      totalUsers: billing.totalUsers,
      userFees: (billing.totalFees /100).toFixed(2), // User fee of $2.20 per user
      totalDirectDebits: (billing.totalDebitAmount / 100).toFixed(2), // Convert from cents to dollars
      averageAmountPerUser: (billing.totalClubAmount / 100).toFixed(2), // Convert from cents to dollars
    }));

    // Generate the Excel sheet with the filtered data
    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ClubsBilling');
    XLSX.writeFile(workbook, 'ClubsBillingSummary.xlsx');
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    const sortedData = [...billingSummary].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setBillingSummary(sortedData);
  };

  // Handle row click, navigating to a new page and passing the supportingClubId
  const handleRowClick = (supportingClubId, name) => {
   
    navigate(`/clubs/club-billing`, { state: { userBillingSummary, supportingClubId, name } });
    
  };

  const handleApprove = async () => {
    setApproveHandlerLoading(true);
    try {
      const headers = await matchHeaders(localStorage.getItem('role'));
      const response = await axios.post(
        'https://minerva.sipora.io/api/reporting/eom-rounda-clubs-mc',
        { tenantName: 'ROUNDA-STAGE' },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: headers.apikey,
            tenant: headers.appTenant,
            tenantA: headers.authTenant,
          },
        }
      );
      if (response) {
      setApprovedStatus(true); // Set the data to state
      alert('Successfully submitted')
      }
      } catch (error) {
      console.error('Error fetching EOM debits:', error);
      alert('Error, please contact PokitPal')

       }
  };

  const handlePayouts = async () => {
    setPayoutHandlerLoading(true);
    try {
      const headers = await matchHeaders(localStorage.getItem('role'));
      const response = await axios.post(
        'https://minerva.sipora.io/api/reporting/eom-rounda-clubs-payouts',
        { tenantName: 'ROUNDA-STAGE' },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: headers.apikey,
            tenant: headers.appTenant,
            tenantA: headers.authTenant,
          },
        }
      );
      if (response) {
      setPayoutStatus(true); // Set the data to state
      alert('Successfully submitted')
      }
      } catch (error) {
      console.error('Error fetching EOM debits:', error);
      alert('Error, please contact PokitPal')

       }
  };

  

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}><CssBaseline />
        <MainNav />
        <Box
          sx={{
            padding: '20px',
            marginLeft: isMobile ? '0' : '300px',
            marginTop: '30px',
            transition: 'margin-left 0.3s',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
            <Typography variant="h2" sx={{ flexGrow: 1 }}>Last Month's Billing</Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}></Box>
{/*
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <TextField
              label="Search Debits"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ flexGrow: 1, marginRight: '10px' }}
            />
          
          </Box>
*/}
          <Box align="right" sx={{ mb: 2, ml: 5 }}>
         
 {userRole === 'ADMIN' && (
  <Button 
    variant="text" 
    color="primary" 
    onClick={handlePayouts}
    sx={{
      backgroundColor: 'pink', 
      color: 'white',
      '&.Mui-disabled': {
        backgroundColor: 'grey',
        color: 'white',
      },
    }}
    disabled={payoutHandlerLoading}
  >
    Process Payouts
  </Button>
)}
          <Button variant="text" color="primary" onClick={handleApprove} 
          sx={{  ml: 4, backgroundColor: 'green', color: 'white',
            '&.Mui-disabled': {
              backgroundColor: 'grey',
              color: 'white',
            },
           }} disabled={approveHandlerLoading}>
              APPROVE
            </Button>
            <Button variant="text" color="primary" onClick={exportToExcel} sx={{ ml: 4, backgroundColor: theme.palette.action.good }}>
              Export to Excel
            </Button>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'name'}
                      direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('name')}
                    >
                      Club Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'uniqueUsers'}
                      direction={sortConfig.key === 'uniqueUsers' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('uniqueUsers')}
                    >
                      Total Users
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>User Fees ($)</TableCell>
                  <TableCell>Total Direct Debits ($)</TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'totalClubAmount'}
                      direction={sortConfig.key === 'totalClubAmount' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('totalClubAmount')}
                    >
                      Total Amount To Club ($)
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      <TableCell><Skeleton variant="text" /></TableCell>
                    </TableRow>
                  ))
                ) : billingSummary.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography>No billing data found.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  billingSummary.map((summary, index) => (
                    <TableRow key={index} onClick={() => handleRowClick(summary.supportingClubId)} sx={{ cursor: 'pointer' }}>
                      <TableCell>{summary.name}</TableCell>
                      <TableCell>{summary.uniqueUsers}</TableCell>
                      <TableCell>{(summary.totalFees / 100).toFixed(2)}</TableCell>
                      <TableCell>{(summary.totalDebitAmount / 100).toFixed(2)}</TableCell>
                      <TableCell>{(summary.totalClubAmount / 100).toFixed(2)}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default ClubsBillingPage;
