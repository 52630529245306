import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Grid2, Card, Modal, CardContent, Box, Container, Typography, useMediaQuery, CircularProgress, TextField, ThemeProvider, CssBaseline, Button  } from '@mui/material';
import RewardsIQNav from '../../components/navigation/RewardsIQNav';
import { api } from '../../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../../services/settings';
import { useTheme } from '@mui/material/styles';


const ChurnDashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userRole = localStorage.getItem('role');
  const [floatALoading, setFloatALoading] = useState(true);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const [churnData, setChurnData] = useState({})
  const navigate = useNavigate(); // Use navigate for navigation
 

 const checkRole = async () => {
  const isAdmin = localStorage.getItem('role');
if (isAdmin !== 'ADMIN') {
  navigate('/dashboard')
}}


 useEffect(() => {
  checkRole();
  const fetchData = async () => {
    setIsLoadingScreen(true);  // Set loading state before requests
    try {
      // Wait for all fetch functions to complete using Promise.all
      await Promise.all([
        fetchChurnData()
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);  // Handle errors if any request fails
    } finally {
      setIsLoadingScreen(false);  // Set loading state to false after requests are completed
    }
  };

  fetchData();  // Call the fetchData function when the component mounts
}, []);
  


  const fetchChurnData = async (headers) => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get('https://phaistos-platform.vercel.app/api/cashback/churn/summary', {
        headers: {
         'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setChurnData(response.data);
      
      
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFloatALoading(false);
    }
  };

  const defaultShadow = '0 2px 14px 0 rgb(32 40 45 / 8%)';

  const cardData = [
    
    { title: 'Churned Users', value: churnData.churnedUsers, percent: ((churnData.churnedUsers/churnData.totalUniqueUsers) * 100 ).toFixed(2), color: '#733aea' },
    { title: 'Churn Risk', value: churnData.churnRiskUsers, percent: ((churnData.churnRiskUsers/churnData.totalUniqueUsers) * 100 ).toFixed(2), color: '#733aea' },
    { title: 'Retained Users', value: churnData.retainedUsers, percent: ((churnData.retainedUsers/churnData.totalUniqueUsers) * 100 ).toFixed(2), color: '#733aea' },
    { title: 'Total Users', value: churnData.totalUniqueUsers, percent: 100, color: '#058efc' },

  ];

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}><CssBaseline />
      <RewardsIQNav />
      <Box
         sx={{
          padding: '20px',
          marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
          marginTop: '30px',
          transition: 'margin-left 0.3s', // Smooth transition when screen size changes
          
        }}
      >

{isLoadingScreen ? (

<Box display="flex" flexDirection="column" alignItems="center" sx={{marginTop: '100px'}}> <CircularProgress size={60} color="secondary"/> </Box>
) : (
  <>
 <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}> 
          <Typography variant="h5" sx={{ flexGrow: 1,  }}> Churn</Typography>
        </Box>

        <Grid2 container spacing={3} mb={6}>
          {cardData.map((card, index) => (
            <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  width: 250, // Ensures the card takes up the full width of the grid item
                  height: 100,   // Fixed height for all cards
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'left',
                  alignItems: 'left',
            //      boxShadow: defaultShadow,
                  borderRadius: '10px',
                  borderColor: '#e3e3e3',
                  color: '#000', // White text for better contrast
                }}
              >
                <CardContent sx={{ textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                 <Box fullWidth>
                  <Grid2 container>
                  <Box padding={2}>
                    {card.icon}
                    </Box>
                    <Grid2 item>
                    <Box>
    <Typography variant='h6' sx={{ fontWeight: "bold", display: 'inline' }}>
      {card.value}
    </Typography>
    <Typography variant='subtitle2' sx={{ ml: 1, display: 'inline', color: 'grey' }}>
      {card.percent}%
    </Typography>
  </Box>
                    <Box><Typography variant='subtitle1' sx={{color: "grey"}}>
                      {card.title}</Typography>
                    </Box>
                    </Grid2>
                  </Grid2>
                 </Box>
                     
                </CardContent>
              </Card>
            </Grid2>
          ))}
        </Grid2>


</>
)};
</Box>
    </ThemeProvider>
    </Container>
  );
};

export default ChurnDashboard;