import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  Table, TableBody, TableCell, TableContainer, TableSortLabel, TableHead, 
  TableRow, Paper, TextField, Button, Box, Container, 
  Typography, useMediaQuery, CircularProgress, 
  CssBaseline, ThemeProvider 
} from '@mui/material';
import MainNav from '../../components/navigation/MainNav';
import { api } from '../../services/apis';
import { matchHeaders, mainTheme, formatCurrency } from '../../services/settings';
import * as XLSX from 'xlsx';

const Payouts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [payments, setPayments] = useState([]); // Initialized as an empty array
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'asc' });
  const userRole = localStorage.getItem('role');
  const navigate = useNavigate();

  useEffect(() => {
    loadPayments(currentPage);
  }, [currentPage]); // Call loadPayments whenever currentPage changes
  
  // Load payments with pagination
  const loadPayments = async (page) => {
    setIsLoading(true);
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get('https://minerva.sipora.io/api/admin/retrieve-payouts', {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        },
        params: {
          page: page,
          limit: 250, // 50 transactions per page
        },
      });

      // Assuming `response.data.payouts` contains the payment records
      setPayments(response.data.payouts.flatMap(payment => payment.payouts || [])); // Flatten the payouts arrays
    } catch (error) {
      console.error('Error loading payments:', error);
      setPayments([]); // Set payments to an empty array if there's an error
    } finally {
      setIsLoading(false);
    }
  };

  // Handle next and previous page for pagination
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Navigate to detail page
  const handleRowClick = (payout) => {
    navigate(`/payouts/${payout.ref}`, { state: { payout } });
  };

  // Sorting function
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });

    // Sort payments based on the selected key and direction
    const sortedPayments = [...payments].sort((a, b) => {
      const valueA = a[key]?.toString().toLowerCase() || ''; // Safely accessing with ?. and providing default value
      const valueB = b[key]?.toString().toLowerCase() || '';

      if (valueA < valueB) {
        return direction === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setPayments(sortedPayments);
  };

  // Export to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(payments);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Payouts');
    XLSX.writeFile(workbook, 'PayoutsData.xlsx');
  };

  // Filter payments based on search query
  const filteredPayments = payments.filter((payout) => {
    const search = searchQuery.toLowerCase();
    return (
      payout.status?.toLowerCase().includes(search) || // Using optional chaining to avoid undefined error
      (payout.description?.toLowerCase().includes(search) || false) // Providing a default value to handle undefined
    );
  });

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <MainNav />
        <Box sx={{ padding: '20px', marginLeft: isMobile ? '0' : '300px', marginTop: '30px', transition: 'margin-left 0.3s' }}>
          <Typography variant="h2" sx={{ marginBottom: '20px', marginTop: '40px' }}> Payouts </Typography>

          {/* Search Field */}
          <Box sx={{ marginBottom: '20px' }}>
            <TextField
              label="Search Payouts"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: '100%', marginBottom: '20px' }}
            />
          </Box>

          <Box align="right" sx={{ mb: 2 }}>
            <Button variant="outlined" onClick={exportToExcel} sx={{ backgroundColor: theme.palette.action.good }}>
              Export to Excel
            </Button>
          </Box>

          {/* Table */}
          <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto', overflowX: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'created_at'}
                      direction={sortConfig.direction}
                      onClick={() => requestSort('created_at')}
                    >
                      Created Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Reference</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center"><CircularProgress /></TableCell>
                  </TableRow>
                ) : filteredPayments.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center"><Typography>No payouts found.</Typography></TableCell>
                  </TableRow>
                ) : (
                  filteredPayments.map((payout) => (
                    <TableRow key={payout.ref} hover onClick={() => handleRowClick(payout)}>
                      <TableCell>{new Date(payout.created_at).toLocaleDateString()}</TableCell>
                      <TableCell>{payout.ref}</TableCell>
                      <TableCell>{payout.status}</TableCell>
                      <TableCell>{formatCurrency(payout.amount / 100)}</TableCell>
                      <TableCell>{payout.description}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>

            {/* Pagination Controls */}
            <Box align="center" sx={{ padding: 2 }}>
              <Button onClick={handlePreviousPage} variant="outlined" disabled={currentPage === 1} sx={{ mr: 1, backgroundColor: theme.palette.action.good }}>
                Previous Page
              </Button>
              <Button onClick={handleNextPage} variant="outlined" disabled={isLoading} sx={{ mr: 1, backgroundColor: theme.palette.action.good }}>
                {isLoading ? 'Loading...' : 'Next Page'}
              </Button>
            </Box>
          </TableContainer>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default Payouts;
