import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    isLoading: false,
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;  // Set the users array
    },
    
    addUser: (state, action) => {
      state.users.push(action.payload);  // Add a user to the array
    },
    removeUser: (state, action) => {
      state.users = state.users.filter(user => user.id !== action.payload);  // Remove a user by ID
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;  // Set loading state
    },
  },
});

export const { setUsers, addUser, removeUser, setLoading } = usersSlice.actions;

export default usersSlice.reducer;
