import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Table, TableBody, TableCell, TableContainer, TableSortLabel, TableHead, TableRow, Paper, TextField, Button, Box, Container, Select, MenuItem, InputLabel, FormControl, Typography, useMediaQuery, CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import MainNav from '../../components/navigation/MainNav';
import { api } from '../../services/apis';
import { matchHeaders, mainTheme } from '../../services/settings';
import { useTheme } from '@mui/material/styles';
import * as XLSX from 'xlsx';

const Clubs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [clubsList, setClubsList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [selectedDGR, setSelectedDGR] = useState('');
  const [distinctDGR, setDistinctDGR] = useState([]);
  const userRole = localStorage.getItem('role');

  const navigate = useNavigate(); // Use navigate for navigation

  useEffect(() => {
    const dgrVerified = [...new Set(clubsList.map(club => mapDGR(club?.DGR_status)))];
    setDistinctDGR(dgrVerified);
  }, [clubsList]);

  const mapDGR = (value) => {
    return value ? '✔' : '✘'; // Tick for true, Cross for false or undefined
  };

  const sortedClubs = [...clubsList].sort((a, b) => {
    const valueA = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
    const valueB = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';

    if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
    if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
    return 0;
  });

  const filteredClubs = sortedClubs.filter(club => {
    const name = club?.name ? club.name.toLowerCase() : '';
    const tenant = club?.tenant ? club.tenant.toLowerCase() : '';
    const dgrVerified = mapDGR(club?.DGR_status);

    return (
      (name.includes(searchQuery.toLowerCase()) ||
        tenant.includes(searchQuery.toLowerCase())) &&
      (selectedDGR === '' || dgrVerified === selectedDGR)
    );
  });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleRowClick = (clubId) => {
    navigate(`/clubs/${clubId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
    setIsLoadingScreen(true);  // Set loading state before requests
        try {
        // Wait for all fetch functions to complete using Promise.all
        await Promise.all([
          fetchClubs()
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);  // Handle errors if any request fails
      } finally {
        setIsLoadingScreen(false);  // Set loading state to false after requests are completed
      }
    };
  
    fetchData();  // Call the fetchData function when the component mounts
  }, []);

  const fetchClubs = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveClubs, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setClubsList(response.data.clubs || []);
    } catch (error) {
      console.error('Error fetching clubs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(clubsList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clubs');
    XLSX.writeFile(workbook, 'ClubsData.xlsx');
  };

  const handleNewClub = () => {
    navigate('/clubs/new')
  }

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}><CssBaseline />
        <MainNav />
        <Box sx={{ padding: '20px', marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
          marginTop: '30px',   transition: 'margin-left 0.3s' }}>

{isLoadingScreen ? (

<Box display="flex" flexDirection="column" alignItems="center" sx={{marginTop: '100px'}}> <CircularProgress size={60} color="secondary"/> </Box>
) : (
  <>


          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
            <Typography variant="h4" sx={{ flexGrow: 1 }}>Clubs</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <TextField
              label="Search Clubs"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ flexGrow: 1, marginRight: '10px' }}
            />
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>DGR Status</InputLabel>
              <Select
                value={selectedDGR}
                onChange={(e) => setSelectedDGR(e.target.value)}
                label="DGR Status"
              >
                <MenuItem value="">All Status</MenuItem>
                {distinctDGR.map((dgrVerified, index) => (
                  <MenuItem key={index} value={dgrVerified}>
                    {dgrVerified}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleNewClub} sx={{ ml: 5, backgroundColor: theme.palette.action.add }} >
              New Club
            </Button>
          </Box>
          <Box align="right" sx={{ mb: 2, ml: 5 }}>
            <Button variant="text" color="primary" onClick={exportToExcel} sx={{ backgroundColor: theme.palette.action.good }}>
              Export to Excel
            </Button>
          </Box>
        
          <TableContainer component={Paper} sx={{ maxHeight: 'calc(100vh - 120px)', overflowY: 'auto', overflowX: 'auto', whiteSpace: 'nowrap', borderRadius: 4, 
            boxShadow: 2}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{fontSize: 12, fontWeight: 'bold'}}>ID</TableCell>
                  <TableCell sx={{fontSize: 12, fontWeight: 'bold'}}>
                    <TableSortLabel
                      active={sortConfig.key === '_createdDate'}
                      direction={sortConfig.key === '_createdDate' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('_createdDate')}
                    >
                      Created Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{fontSize: 12, fontWeight: 'bold'}}>
                    <TableSortLabel
                      active={sortConfig.key === 'name'}
                      direction={sortConfig.key === 'name' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('name')}
                    >
                      Club Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{fontSize: 12, fontWeight: 'bold'}}>Parent Club</TableCell>
                  <TableCell sx={{fontSize: 12, fontWeight: 'bold'}}>Contact Name</TableCell>
                  <TableCell sx={{fontSize: 12, fontWeight: 'bold'}}>Email</TableCell>
                  <TableCell sx={{fontSize: 12, fontWeight: 'bold'}}>Verified</TableCell>
                  <TableCell sx={{fontSize: 12, fontWeight: 'bold'}}>DGR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : clubsList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      <Typography>No clubs found.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredClubs.map((club) => (
                    <TableRow key={club._id} onClick={() => handleRowClick(club._id)} sx={{ cursor: 'pointer' }}>
                      <TableCell sx={{fontSize: 12}}>{club._id}</TableCell>
                      <TableCell sx={{fontSize: 12}}>{new Date(club._createdDate).toLocaleDateString()}</TableCell>
                      <TableCell sx={{ fontSize: 12, maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {club.name.length > 200 ? `${club.name.substring(0, 100)}...` : club.name}
                      </TableCell>
                      <TableCell sx={{fontSize: 12}}>{club.parent_club}</TableCell>
                      <TableCell sx={{fontSize: 12}}>{club.contact}</TableCell>
                      <TableCell sx={{fontSize: 12}}>{club.email}</TableCell>
                      <TableCell sx={{fontSize: 12}}>{club.verified ? '✔' : ''}</TableCell>
                      <TableCell sx={{fontSize: 12}}>{club.DGR_status ? '✔' : ''}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>

          </Table>
        </TableContainer>
        </> )}
      </Box></ThemeProvider>
    </Container>
  );
};

export default Clubs;