import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Grid2, Card, Modal, CardContent, Box, Container, Typography, useMediaQuery, CircularProgress, TextField, ThemeProvider, CssBaseline, Button  } from '@mui/material';
import RewardsIQNav from '../../components/navigation/RewardsIQNav';
import { api } from '../../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../../services/settings';
import { useTheme } from '@mui/material/styles';





const RewardsIQDashboard = () => {
   const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const userRole = localStorage.getItem('role');
  const [floatALoading, setFloatALoading] = useState(true);
  const [floatATopUpLoading, setFloatATopUpLoading] = useState(true);
  const [floatBLoading, setFloatBLoading] = useState(true);
  const [floatBTopUpLoading, setFloatBTopUpLoading] = useState(true);
  const [floatCLoading, setFloatCLoading] = useState(true);
  const [nRMATopUpValue, setNRMATopUpValue] = useState(0);
  const [pokitPalTopUpValue, setPokitPalTopUpValue] = useState(0);
  const [openPPTopUp, setOpenPPTopUp] = React.useState(false);
  const [ppAmount, setPPAmount] = useState(0);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  


  const [NRMAFloatSummary, setNRMAFloatSummary] = useState({
  totalPayouts: 0,
  numberOfPayouts: 0,
  averageAmountPerDay: 0,
  availableBalance: 0,
  daysLeft: 0
  });
  const [PokitPalFloatSummary, setPokitPalFloatSummary] = useState({
    totalPayouts: 0,
    numberOfPayouts: 0,
    averageAmountPerDay: 0,
    availableBalance: 0,
    daysLeft: 0
    });

  const [RoundUpFloatSummary, setRoundUpFloatSummary] = useState({
      totalPayouts: 0,
      numberOfPayouts: 0,
      averageAmountPerDay: 0,
      availableBalance: 0,
      daysLeft: 0
      });
    


    
  const navigate = useNavigate(); // Use navigate for navigation
 
  useEffect(() => {
    checkRole();
 }, []);

 const checkRole = async () => {
  const isAdmin = localStorage.getItem('role');
if (isAdmin !== 'ADMIN') {
  navigate('/dashboard')
}
 }


 useEffect(() => {
  const fetchData = async () => {
    setIsLoadingScreen(true);  // Set loading state before requests

    try {
      // Wait for all fetch functions to complete using Promise.all
      await Promise.all([
        fetchNRMAFloat(),
        fetchNRMATopUp(),
        fetchPokitPalFloat(),
        fetchPokitPalTopUp(),
        fetchRoundUpFloatStatus()
      ]);
    } catch (error) {
      console.error('Error fetching data:', error);  // Handle errors if any request fails
    } finally {
      setIsLoadingScreen(false);  // Set loading state to false after requests are completed
    }
  };

  fetchData();  // Call the fetchData function when the component mounts
}, []);
  


  const fetchNRMAFloat = async (headers) => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveNrmaFloat, {
        headers: {
         'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setNRMAFloatSummary(response.data.summary);
      
      
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFloatALoading(false);
    }
  };

  const fetchNRMATopUp = async (headers) => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveNrmaTopUp, {
        headers: {
         'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setNRMATopUpValue(response.data.suggestedTopUpValue);
      
      
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFloatATopUpLoading(false);
    }
  };

  const fetchPokitPalFloat = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrievePokitPalFloat, {
        headers: {
         'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setPokitPalFloatSummary(response.data.summary);
      
      
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFloatBLoading(false);
    }
  };

  const fetchPokitPalTopUp = async (headers) => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrievePokitPalTopUp, {
        headers: {
         'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setPokitPalTopUpValue(response.data.suggestedTopUpValue);
      
      
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFloatATopUpLoading(false);
    }
  };
  

  const fetchRoundUpFloatStatus = async () => {
    try {
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveRoundUpFloat, {
        headers: {
         'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setRoundUpFloatSummary(response.data.summary);
      
      
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFloatCLoading(false);
    }};
    
    const handleOpenPPTopUp = () => {
      setOpenPPTopUp(true);
    };
    const handleClosePPTopUp = () => {
      setOpenPPTopUp(false);
    };
  
    const handlePPAmountChange = (event) => {
      setPPAmount(event.target.value);  // Update the state with the input value
    };


    const handlePokitPalTopUp = async () => {
      try {

        const headers = await matchHeaders(userRole);
        const response = await axios.post(api.topUpFloatPokitPal, {value: ppAmount},
          {
          headers: {
           'Content-Type': 'application/json',
            apikey: headers.apikey,
            tenant: headers.appTenant,
            tenantA: headers.authTenant,
          }
        });
        console.log(response);
      } catch (error) {
        console.error('Error topping up', error);
      } finally {
        alert('TOP UP COMPLETE')
      }};


 


  const defaultShadow = '0 2px 14px 0 rgb(32 40 45 / 8%)';

  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}><CssBaseline />
      <RewardsIQNav />
      <Box
         sx={{
          padding: '20px',
          marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
          marginTop: '30px',
          transition: 'margin-left 0.3s', // Smooth transition when screen size changes
          
        }}
      >

{isLoadingScreen ? (

<Box display="flex" flexDirection="column" alignItems="center" sx={{marginTop: '100px'}}> <CircularProgress size={60} color="secondary"/> </Box>
) : (
  <>
 <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}> 
          <Typography variant="h2" sx={{ flexGrow: 1,  }}> Floats</Typography>
        </Box>

        <Grid2 container space={3} justifyContent="left">
        <Grid2 item padding={3}>
        <Card
                sx={{
                  width: 450, // Ensures the card takes up the full width of the grid item
                  padding: 2,   // Fixed height for all cards
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: defaultShadow,
                  borderRadius: '16px',
                  backgroundColor: '#733aea', // Card-specific color
                  color: '#fff', // White text for better contrast
                }}
              >
        <CardContent>
        {floatALoading ? (
  <CircularProgress />
) : (
  <>
<Typography variant='h3' sx={{marginBottom: '20px'}}>NRMA</Typography>
<Typography>Total Payouts: {formatCurrency(-NRMAFloatSummary.totalPayouts)}</Typography>
<Typography>Number of Payouts: {NRMAFloatSummary.numberOfPayouts}</Typography>
<Typography>Average Amount Per Day: {formatCurrency(-NRMAFloatSummary.averageAmountPerDay)}</Typography>
<Typography>Available Balance: {formatCurrency(NRMAFloatSummary.availableBalance)}</Typography>
<Typography>Days Left: {NRMAFloatSummary.daysLeft}</Typography>
<Typography>Suggested Top Up Value: {formatCurrency(-nRMATopUpValue)}</Typography>
{nRMATopUpValue !== 0 && (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', marginBottom: '20px', marginTop: '40px' }}> 
  <Button variant="contained"  sx={{ backgroundColor: theme.palette.action.add }}>Top Up Now</Button>
</Box>
)

}
</>
 )}
</CardContent></Card>
</Grid2>

<Grid2 item padding={3}>
<Card
                sx={{
                  width: 450, // Ensures the card takes up the full width of the grid item
                  padding: 2,   // Fixed height for all cards
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: defaultShadow,
                  borderRadius: '16px',
                  backgroundColor: '#f2426e', // Card-specific color
                  color: '#fff', // White text for better contrast
                }}
              >
        <CardContent>
        {floatBLoading ? (
  <CircularProgress />
) : (
  <>
        <Typography variant='h3' sx={{marginBottom: '20px'}}>PokitPal</Typography>
<Typography>Total Payouts: {formatCurrency(-PokitPalFloatSummary.totalPayouts)}</Typography>
<Typography>Number of Payouts: {PokitPalFloatSummary.numberOfPayouts}</Typography>
<Typography>Average Amount Per Day: {formatCurrency(-PokitPalFloatSummary.averageAmountPerDay)}</Typography>
<Typography>Available Balance: {formatCurrency(PokitPalFloatSummary.availableBalance)}</Typography>
<Typography>Days Left: {PokitPalFloatSummary.daysLeft}</Typography>
<Typography>Suggested Top Up Value: {formatCurrency(-pokitPalTopUpValue)}</Typography>
{pokitPalTopUpValue !== 0 && (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right', marginBottom: '20px', marginTop: '40px' }}> 
  <Button variant="contained"  onClick={handleOpenPPTopUp} sx={{ backgroundColor: theme.palette.action.add }}>Top Up Now</Button>
</Box>
)

}
</>
 )}
</CardContent></Card>
</Grid2>

<Grid2 item padding={3}>
<Card
                sx={{
                  width: 450, // Ensures the card takes up the full width of the grid item
                  padding: 2,   // Fixed height for all cards
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: defaultShadow,
                  borderRadius: '16px',
                  backgroundColor: '#fd9722', // Card-specific color
                  color: '#fff', // White text for better contrast
                }}
              >
        <CardContent>
        {floatCLoading ? (
  <CircularProgress />
) : (
  <>
        <Typography variant='h3' sx={{marginBottom: '20px'}}>Round-Up</Typography>
<Typography>Total Payouts: {formatCurrency(-RoundUpFloatSummary.totalPayouts)}</Typography>
<Typography>Number of Payouts: {RoundUpFloatSummary.numberOfPayouts}</Typography>
<Typography>Average Amount Per Day: {formatCurrency(-RoundUpFloatSummary.averageAmountPerDay)}</Typography>
<Typography>Available Balance: {formatCurrency(RoundUpFloatSummary.availableBalance)}</Typography>
<Typography>Days Left: {RoundUpFloatSummary.daysLeft}</Typography>
</>
 )}
</CardContent></Card>
</Grid2>



</Grid2>

<Modal
  open={openPPTopUp}
  onClose={handleClosePPTopUp}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
>
<Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',  // This centers the modal
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}
  >
    <Typography variant='h5' sx={{ marginBottom: 2}}>Top Up Value</Typography>
    <Typography variant='body2'sx={{ marginBottom: 2}}>Suggested top up value for 14 days is {formatCurrency(-pokitPalTopUpValue)}</Typography>
    <TextField fullWidth id='ppAmount' name='ppAmount' value={ppAmount} label='Amount'  onChange={handlePPAmountChange} required></TextField>
    <Typography variant='caption'sx={{ marginBottom: 2}}>*$1 is 1, $50 is 50</Typography>
    <Button fullWidth variant="contained" sx={{ marginTop: 2} } onClick={handlePokitPalTopUp}>TopUp</Button>

   
  </Box>
</Modal>
</>
)};
</Box>
    </ThemeProvider>
    </Container>
  );
};

export default RewardsIQDashboard;