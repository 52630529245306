import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Table, TableBody, TableCell, TableContainer, TableSortLabel, TableHead, TableRow, Paper, TextField, Button, Box, Container, Select, MenuItem, InputLabel, FormControl, Typography, useMediaQuery, CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import MainNav from '../components/navigation/MainNav';
import { api } from '../services/apis';
import { matchHeaders, mainTheme, formatCurrency } from '../services/settings';
import { useTheme } from '@mui/material/styles';
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';


const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const userRole = localStorage.getItem('role');
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [users , setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'firstName', direction: 'asc' });
  const [selectedTenant, setSelectedTenant] = useState('');
  const [selectedConsent, setSelectedConsent] = useState('');
  const [distinctTenants, setDistinctTenants] = useState([]);
  const [distinctConsents, setDistinctConsents] = useState([]);
  const [selectedPaused, setSelectedPaused] = useState('');
  const [distinctPaused, setDistinctPaused] = useState([]);
  const [selectedOnboarded, setSelectedOnboarded] = useState('');
  const [distinctOnboarded, setDistinctOnboarded] = useState([]);

  const navigate = useNavigate(); // Use navigate for navigation

  useEffect(() => {
    console.log(users);  // Check if users are populated
  }, [users]);

  useEffect(() => {
    const tenants = [...new Set(users.map(user => user.tenant))];
    setDistinctTenants(tenants);
  }, [users]);

  useEffect(() => {
    const consents = [...new Set(users.map(user => mapOnboarded(user.onboardingComplete)))];
    setDistinctOnboarded(consents);
  }, [users]);

  useEffect(() => {
    const consents = [...new Set(users.map(user => mapConsent(user.isConsent)))];
    setDistinctConsents(consents);
  }, [users]);

  useEffect(() => {
    const paused = [...new Set(users.map(user => mapPaused(user.isUserPausedRoundUp)))];
    setDistinctPaused(paused);
  }, [users]);

  // Mapping consent values to "✔" and "✘"
  const mapConsent = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  const mapPaused = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  const mapOnboarded = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  // Sorting function
    const sortedUsers = [...users].sort((a, b) => {
    const valueA = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
    const valueB = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';

    if (valueA < valueB) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Filter users based on the search query and selected tenant
  const filteredUsers = sortedUsers.filter(user => {
    const firstName = user.firstName ? user.firstName.toLowerCase() : '';
    const lastName = user.lastName ? user.lastName.toLowerCase() : '';
    const email = user.email ? user.email.toLowerCase() : '';
    const tenant = user.tenant ? user.tenant.toLowerCase() : '';
    const consent = mapConsent(user.isConsent);
    const paused = mapPaused(user.isUserPausedRoundUp);
    const onboarded = mapOnboarded(user.onboardingComplete);

    return (
      (firstName.includes(searchQuery.toLowerCase()) ||
        lastName.includes(searchQuery.toLowerCase()) ||
        email.includes(searchQuery.toLowerCase()) ||
        tenant.includes(searchQuery.toLowerCase())) &&
      (selectedTenant === '' || user.tenant === selectedTenant) &&
      (selectedConsent === '' || consent === selectedConsent) &&
      (selectedPaused === '' || paused === selectedPaused) &&
      (selectedOnboarded === '' || onboarded === selectedOnboarded)
    );
  });

  // Change sort direction and key
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Navigate to user details page
  const handleRowClick = (userId) => {
    navigate(`/users/${userId}`);
  };


  useEffect(() => {

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingScreen(true);  // Set loading state before requests
  
      try {
        // Wait for all fetch functions to complete using Promise.all
        await Promise.all([
          fetchUsers()
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);  // Handle errors if any request fails
      } finally {
        setIsLoadingScreen(false);  // Set loading state to false after requests are completed
      }
    };
  
    fetchData();  // Call the fetchData function when the component mounts
  }, []);

  const fetchUsers = async () => {
    try {
      const userRole = localStorage.getItem('role');
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveUsers, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };



  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(users); // Convert JSON data to sheet format
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users'); // Append sheet to workbook
    XLSX.writeFile(workbook, 'UsersData.xlsx'); // Generate Excel file and download
  };
 
  



  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <MainNav />
        <Box
          sx={{
            padding: '20px',
            marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
            marginTop: '30px',
            transition: 'margin-left 0.3s', // Smooth transition when screen size changes
          }}
        >
          {isLoadingScreen ? (
            // Skeleton Loader for Table Header and Filters
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
                <Skeleton variant="text" width="200px" height="40px" />
              </Box>
  
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <Skeleton variant="rectangular" width="100%" height="56px" sx={{ marginRight: '10px' }} />
                <Skeleton variant="rectangular" width="200px" height="56px" />
                <Skeleton variant="rectangular" width="200px" height="56px" />
                <Skeleton variant="rectangular" width="200px" height="56px" />
                <Skeleton variant="rectangular" width="200px" height="56px" />
              </Box>
  
              {/* Skeleton Loader for Export Button */}
              <Box align="right" sx={{ mb: 2 }}>
                <Skeleton variant="rectangular" width="150px" height="40px" />
              </Box>
  
              {/* Skeleton Loader for Table Rows */}
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 'calc(100vh - 120px)',
                  overflowY: 'auto',
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                  borderRadius: 4,
                  boxShadow: 2,
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>ID</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Consent</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Onboarded</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Paused</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Created Date</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Tenant</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Email</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>First Name</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Last Name</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Max</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from(new Array(8)).map((_, index) => (
                      <TableRow key={index}>
                        {Array.from(new Array(10)).map((_, cellIndex) => (
                          <TableCell key={cellIndex}>
                            <Skeleton variant="text" width="100%" height="30px" />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
                <Typography variant="h4" sx={{ flexGrow: 1 }}>
                  Users
                </Typography>
              </Box>
  
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                <TextField
                  label="Search Users"
                  variant="outlined"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{ flexGrow: 1, marginRight: '10px' }}
                />
                {userRole === 'ADMIN' && (
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Filter by Tenant</InputLabel>
                    <Select
                      value={selectedTenant}
                      onChange={(e) => setSelectedTenant(e.target.value)}
                      label="Filter by Tenant"
                    >
                      <MenuItem value="">All Tenants</MenuItem>
                      {distinctTenants.map((tenant, index) => (
                        <MenuItem key={index} value={tenant}>
                          {tenant}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
  
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>Filter by Consent</InputLabel>
                  <Select
                    value={selectedConsent}
                    onChange={(e) => setSelectedConsent(e.target.value)}
                    label="Filter by Consent"
                  >
                    <MenuItem value="">All Status</MenuItem>
                    {distinctConsents.map((consent, index) => (
                      <MenuItem key={index} value={consent}>
                        {consent}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
  
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>Filter by Onboarded</InputLabel>
                  <Select
                    value={selectedOnboarded}
                    onChange={(e) => setSelectedOnboarded(e.target.value)}
                    label="Filter by Onboarded"
                  >
                    <MenuItem value="">All Status</MenuItem>
                    {distinctOnboarded.map((onboarded, index) => (
                      <MenuItem key={index} value={onboarded}>
                        {onboarded}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
  
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel>Filter by Paused</InputLabel>
                  <Select
                    value={selectedPaused}
                    onChange={(e) => setSelectedPaused(e.target.value)}
                    label="Filter by Paused"
                  >
                    <MenuItem value="">All Status</MenuItem>
                    {distinctPaused.map((paused, index) => (
                      <MenuItem key={index} value={paused}>
                        {paused}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
  
              <Box align="right" sx={{ mb: 2 }}>
                <Button variant="outlined" onClick={exportToExcel} sx={{ backgroundColor: theme.palette.action.good }}>
                  Export to Excel
                </Button>
              </Box>
  
              {/* Table Container */}
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: 'calc(100vh - 120px)',
                  overflowY: 'auto',
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                  borderRadius: 4,
                  boxShadow: 2,
                }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>ID</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'isConsent'}
                          direction={sortConfig.key === 'isConsent' ? sortConfig.direction : 'asc'}
                          onClick={() => requestSort('isConsent')}
                        >
                          Consent
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'onboardingComplete'}
                          direction={sortConfig.key === 'onboardingComplete' ? sortConfig.direction : 'asc'}
                          onClick={() => requestSort('onboardingComplete')}
                        >
                          Onboarded
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'onboardingComplete'}
                          direction={sortConfig.key === 'onboardingComplete' ? sortConfig.direction : 'asc'}
                          onClick={() => requestSort('onboardingComplete')}
                        >
                          Paused
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                        <TableSortLabel
                          active={sortConfig.key === '_createdDate'}
                          direction={sortConfig.key === '_createdDate' ? sortConfig.direction : 'asc'}
                          onClick={() => requestSort('_createdDate')}
                        >
                          Created Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'tenant'}
                          direction={sortConfig.key === 'tenant' ? sortConfig.direction : 'asc'}
                          onClick={() => requestSort('tenant')}
                        >
                          Tenant
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Email</TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'firstName'}
                          direction={sortConfig.key === 'firstName' ? sortConfig.direction : 'asc'}
                          onClick={() => requestSort('firstName')}
                        >
                          First Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>
                        <TableSortLabel
                          active={sortConfig.key === 'lastName'}
                          direction={sortConfig.key === 'lastName' ? sortConfig.direction : 'asc'}
                          onClick={() => requestSort('lastName')}
                        >
                          Last Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell sx={{ fontSize: 12, fontWeight: 'bold' }}>Max</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          <Typography>No users found.</Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredUsers.map((user) => (
                        <TableRow key={user._id} onClick={() => handleRowClick(user._id)} sx={{ cursor: 'pointer' }}>
                          <TableCell sx={{ fontSize: 12 }}>{user._id}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>{user.isConsent ? '✔' : ''}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>{user.onboardingComplete ? '✔' : ''}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>{user.isUserPausedRoundUp ? '✔' : ''}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>{new Date(user._createdDate).toLocaleDateString()}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>{user.tenant}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>{user.email}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>{user.firstName}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>{user.lastName}</TableCell>
                          <TableCell sx={{ fontSize: 12 }}>
                            {user.weeklyMaximumLimit != null && user.weeklyMaximumLimit !== ''
                              ? formatCurrency(user.weeklyMaximumLimit / 100)
                              : ''}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default Users;