import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box, Typography, CircularProgress, Container, CssBaseline, Paper,
  ThemeProvider,
} from '@mui/material';
import MainNav from '../../components/navigation/MainNav';
import { mainTheme, formatCurrency } from '../../services/settings';

const PaymentDetail = () => {
  const { ref } = useParams(); // Get payment reference from the URL
  const [paymentDetail, setPaymentDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { payout } = location.state || {}; // Retrieve the payout from state if available

  // Use useEffect to handle the loading state
  useEffect(() => {
    if (payout) {
      // If payout data is already available from state, no need to fetch again
      setPaymentDetail(payout);
      setIsLoading(false);
    } else {
      // If payout data is not available in state, set loading to false to proceed
      setIsLoading(false);
    }
  }, [payout]);

  return (
    <Container maxWidth="md">
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <MainNav />
        <Box sx={{ padding: '20px', marginTop: '30px' }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
              <CircularProgress />
            </Box>
          ) : paymentDetail ? (
            <Paper sx={{ padding: '20px' }}>
              <Typography variant="h4" gutterBottom>Payout Detail</Typography>
              <Typography variant="body1"><strong>Reference:</strong> {paymentDetail.ref}</Typography>
              <Typography variant="body1"><strong>Status:</strong> {paymentDetail.status}</Typography>
              <Typography variant="body1"><strong>Amount:</strong> {formatCurrency(paymentDetail.amount / 100)}</Typography>
              <Typography variant="body1"><strong>Description:</strong> {paymentDetail.description}</Typography>
              <Typography variant="body1"><strong>Created At:</strong> {new Date(paymentDetail.created_at).toLocaleDateString()}</Typography>
              <Typography variant="body1"><strong>Batch Description:</strong> {paymentDetail.batch_description}</Typography>
              <Typography variant="body1"><strong>Matures At:</strong> {new Date(paymentDetail.matures_at).toLocaleDateString()}</Typography>
              <Typography variant="body1"><strong>From ID:</strong> {paymentDetail.from_id}</Typography>
              <Typography variant="body1"><strong>To ID:</strong> {paymentDetail.to_id}</Typography>
              <Typography variant="body1"><strong>Recipient Contact ID:</strong> {paymentDetail.recipient_contact_id}</Typography>

              {/* Metadata Fields */}
              <Box sx={{ marginTop: '20px' }}>
                <Typography variant="h5" gutterBottom>Metadata</Typography>
                {paymentDetail.metadata && (
                  <>
                    <Typography variant="body1"><strong>User ID:</strong> {paymentDetail.metadata.userId}</Typography>
                    <Typography variant="body1"><strong>Payout ID:</strong> {paymentDetail.metadata.payoutId}</Typography>
                    <Typography variant="body1"><strong>Wallet ID:</strong> {paymentDetail.metadata.walletId}</Typography>
                    <Typography variant="body1"><strong>Network ID:</strong> {paymentDetail.metadata.networkId}</Typography>
                    <Typography variant="body1"><strong>Zepto Payment Log ID:</strong> {paymentDetail.metadata.zeptoPaymentLogId}</Typography>
                  </>
                )}
              </Box>
            </Paper>
          ) : (
            <Typography variant="h6" align="center">No details available for this payment.</Typography>
          )}
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default PaymentDetail;
