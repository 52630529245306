import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableSortLabel, TableHead, TableRow,
  Paper, TextField, Button, Box, Container, Select, MenuItem, InputLabel, FormControl,
  Typography, useMediaQuery, Skeleton, CssBaseline, ThemeProvider
} from '@mui/material';
import MainNav from '../../components/navigation/MainNav';
import { api } from '../../services/apis';
import { matchHeaders, mainTheme, formatCurrency } from '../../services/settings';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';

const ClubsBillingPageClub = () => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [billingSummary, setBillingSummary] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
  const [selectedTenant, setSelectedTenant] = useState('');
  const [distinctTenants, setDistinctTenants] = useState([]); // Track tenants for filtering
  const [filteredUsers, setFilteredUsers] = useState([]);
  const location = useLocation();
  const { userBillingSummary, supportingClubId } = location.state || {};
  const navigate = useNavigate();


  useEffect(() => {
    if (userBillingSummary && supportingClubId) {
      const usersForClub = userBillingSummary.filter(user => user.supportingClubId === supportingClubId);
      setFilteredUsers(usersForClub);
    }
  }, [userBillingSummary, supportingClubId]);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = userBillingSummary.filter(user => 
      user.supportingClubId === supportingClubId &&
      user.email.toLowerCase().includes(query)
    );
    setFilteredUsers(filtered);
  };

  const exportToExcel = () => {
    const filteredDataForExport = userBillingSummary.map(billing => ({
      email: billing.email,
      savingsAmount: (billing.savingsAmount /100).toFixed(2), // User fee of $2.20 per user
      toitalDebitAmount: (billing.totalDebitAmount / 100).toFixed(2), // Convert from cents to dollars
      clubAmount: (billing.clubAmount / 100).toFixed(2), // Convert from cents to dollars
      roundaAmount: (billing.roundaAmount / 100).toFixed(2), // Convert from cents to dollars
    }));

    // Generate the Excel sheet with the filtered data
    const worksheet = XLSX.utils.json_to_sheet(filteredDataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ClubsBilling');
    XLSX.writeFile(workbook, 'ClubsBillingSummary.xlsx');
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    } 
    setSortConfig({ key, direction });

    const sortedData = [...billingSummary].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredUsers(sortedData);
  };

  const handleApprove = async => {
    console.log(filteredUsers);
  };

 
  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}><CssBaseline />
        <MainNav />
        <Box
          sx={{
            padding: '20px',
            marginLeft: isMobile ? '0' : '300px',
            marginTop: '30px',
            transition: 'margin-left 0.3s',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}>
            <Typography variant="h2" sx={{ flexGrow: 1 }}>Club Users Billing</Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', marginTop: '40px' }}></Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
            <TextField
              label="Search by Email"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearch}
              sx={{ flexGrow: 1, marginRight: '10px' }}
            />
           
          </Box>

          <Box align="right" sx={{ mb: 2, ml: 5 }}>
            <Button variant="text" color="primary" onClick={exportToExcel} sx={{ backgroundColor: theme.palette.action.good }}>
              Export to Excel
            </Button>
            
          </Box>

          <TableContainer component={Paper}>
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={sortConfig.key === 'email'}
                      direction={sortConfig.key === 'email' ? sortConfig.direction : 'asc'}
                      onClick={() => requestSort('email')}
                    >
                      Email
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Total Debit Amount ($)</TableCell>
                  <TableCell>Savings Amount ($)</TableCell>
                  <TableCell>Club Amount ($)</TableCell>
                  <TableCell>Rounda Amount ($)</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {isLoading ? (
                  Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      <TableCell><Skeleton variant="text" /></TableCell>
                      <TableCell><Skeleton variant="text" /></TableCell>
                    </TableRow>
                  ))
                ) : filteredUsers.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography>No users found for this club.</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredUsers.map((user, index) => (
                    <TableRow key={index} sx={{ cursor: 'pointer' }}>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{(user.totalDebitAmount / 100).toFixed(2)}</TableCell>
                      <TableCell>{(user.savingsAmount / 100).toFixed(2)}</TableCell>
                      <TableCell>{(user.clubAmount / 100).toFixed(2)}</TableCell>
                      <TableCell>{(user.roundaAmount / 100).toFixed(2)}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default ClubsBillingPageClub;
