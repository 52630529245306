import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Grid2, Card, CardContent, Box, Container, Typography, useMediaQuery, CircularProgress, ThemeProvider, CssBaseline, Tabs, Tab  } from '@mui/material';
import MainNav from '../components/navigation/MainNav';
import { api } from '../services/apis';
import { mainTheme, formatCurrency, matchHeaders } from '../services/settings';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';
import {  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { format } from 'date-fns';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GroupIcon from '@mui/icons-material/Group';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FaUsers } from 'react-icons/fa';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Skeleton from '@mui/material/Skeleton';




const DashboardNew = () => {
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);
  const location = useLocation();
  const userRole = localStorage.getItem('role');
  const [users, setUsers] = useState([]);
  const [roundUpsIsLoading, setRoundUpsIsLoading] = useState(true);
  const [usersIsLoading, setUsersIsLoading] = useState(true);
  const [loadingClubs, setLoadingClubs] = useState(true);
  const theme = mainTheme;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [chartData, setChartData] = useState([]);
  const [chartDataTransactions, setChartDataTransactions] = useState([]);
  const [clubCount, setClubCount] = useState('');
  const [usersByMonth, setUsersByMonth] = useState([]);
  const [totalMaturing, setTotalMaturing] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'firstName', direction: 'asc' });
  const [selectedTenant, setSelectedTenant] = useState('');
  const [selectedConsent, setSelectedConsent] = useState('');
  const [selectedOnboarded, setSelectedOnboarded] = useState('');
  const [selectedPaused, setSelectedPaused] = useState('');
  const [distinctTenants, setDistinctTenants] = useState([]);
  const [distinctConsents, setDistinctConsents] = useState([]);
  const [distinctOnboarded, setDistinctOnboarded] = useState([]);
  const [distinctPaused, setDistinctPaused] = useState([]);
  const [totalRoundUps, setTotalRoundUps] = useState('');
  const [inMonthRoundUps, setInMonthRoundUps] = useState('');
  const [lastMonthRoundUps, setLastMonthRoundUps] = useState('');
  const [NRMAPayoutAlertsData, setNRMAPayoutAlertsData] = useState({});
  const [pokitPalPayoutAlertsData, setPokitPalPayoutAlertsData] = useState({});
  const [alertCount, setAlertCount] = useState(0);

  
  const navigate = useNavigate(); // Use navigate for navigation

  useEffect(() => {
    const tenants = [...new Set(users.map(user => user.tenant))];
    setDistinctTenants(tenants);
  }, [users]);

  useEffect(() => {
    const consents = [...new Set(users.map(user => mapOnboarded(user.onboardingComplete)))];
    setDistinctConsents(consents);
  }, [users]);

  useEffect(() => {
    const consents = [...new Set(users.map(user => mapConsent(user.isConsent)))];
    setDistinctConsents(consents);
  }, [users]);

  useEffect(() => {
    const paused = [...new Set(users.map(user => mapPaused(user.isUserPausedRoundUp)))];
    setDistinctPaused(paused);
  }, [users]);


 

  // Mapping consent values to "✔" and "✘"
  const mapConsent = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  const mapPaused = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  const mapOnboarded = (value) => {
    if (value === true) {
      return '✔'; // Tick for true
    } else {
      return '✘'; // Cross for false or undefined
    }
  };

  // Sorting function
  const sortedUsers = [...users].sort((a, b) => {
    const valueA = a[sortConfig.key] ? a[sortConfig.key].toString().toLowerCase() : '';
    const valueB = b[sortConfig.key] ? b[sortConfig.key].toString().toLowerCase() : '';

    if (valueA < valueB) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Filter users based on the search query and selected tenant
  const filteredUsers = sortedUsers.filter(user => {
    const firstName = user.firstName ? user.firstName.toLowerCase() : '';
    const lastName = user.lastName ? user.lastName.toLowerCase() : '';
    const email = user.email ? user.email.toLowerCase() : '';
    const tenant = user.tenant ? user.tenant.toLowerCase() : '';
    const consent = mapConsent(user.isConsent);
    const onboarded = mapOnboarded(user.onboardingComplete);
    const paused = mapPaused(user.isUserPausedRoundUp);

    return (
      (firstName.includes(searchQuery.toLowerCase()) ||
        lastName.includes(searchQuery.toLowerCase()) ||
        email.includes(searchQuery.toLowerCase()) ||
        tenant.includes(searchQuery.toLowerCase())) &&
      (selectedTenant === '' || user.tenant === selectedTenant) &&
      (selectedConsent === '' || consent === selectedConsent) &&
      (selectedOnboarded === '' || onboarded === selectedOnboarded) &&
      (selectedPaused === '' || paused === selectedPaused)
    );
  });

  const totalusers = users.length;
  const consentedUsersCount = filteredUsers.filter(user => user.isConsent === true).length;
  const onboardedUsersCount = filteredUsers.filter(user =>  user.onboardingComplete === true).length;
  const pausedUsersCount = filteredUsers.filter(user =>  user.isUserPausedRoundUp === true).length;
  const activeUsers = onboardedUsersCount - pausedUsersCount;

  

  // Change sort direction and key
  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Navigate to user details page
  const handleRowClick = (userId) => {
    navigate(`/users/${userId}`);
  };

   
  useEffect(() => {
    const loadDashboard = async () => {
      setIsDashboardLoading(true);  // Set loading state to true
  
      const userRole = localStorage.getItem('role');
  
      try {
        const promises = [fetchTotalRoundUps(), fetchUsers() ];
        if (userRole === 'ADMIN') {
          promises.push(fetchClubList(), fetchPayoutAlerts());
         }
         if (userRole === 'ROUNDA') {
          promises.push(fetchClubList());
         }
        await Promise.all(promises);
  
      } catch (error) {
        console.error("Error loading dashboard:", error);  // Handle errors
      } finally {
        setIsDashboardLoading(false);  // Set loading state to false after all promises resolve
      }
    };
  
    loadDashboard();  // Call the loadDashboard function when the component mounts
  }, []);
  
  const fetchPayoutAlerts = async () => {
    try {
          const userRole = localStorage.getItem('role');
            const headers = await matchHeaders(userRole);
            const response = await axios.get('https://minerva.sipora.io/api/admin/payoutsAlert', {
              headers: {
                'Content-Type': 'application/json',
                apikey: headers.apikey,
                tenant: headers.appTenant,
                tenantA: headers.authTenant,
              }
            });
  
            const pokitPalPayouts = response.data.pokitPalPayouts.documents.length > 0 ? response.data.pokitPalPayouts.documents[0] : null;
            const nrmaPayouts = response.data.nrmaPayouts.documents.length > 0 ? response.data.nrmaPayouts.documents[0] : null;
            const payoutAlertCount = response.data.pokitPalPayouts.documents.length + response.data.nrmaPayouts.documents.length;
            setAlertCount(payoutAlertCount)


            if (nrmaPayouts) {
               setNRMAPayoutAlertsData(nrmaPayouts)
            } else {
                console.log('No NRMA payouts found.');
            }
            
            if (pokitPalPayouts) {
              setPokitPalPayoutAlertsData(pokitPalPayouts)
            } else {
                console.log('No PokitPal payouts found.');
            }

            
            
      
    } catch (error) {
      console.error('Error fetching payoutAlerts:', error);
    } finally {
    
  }};
  


 const fetchClubList = async () => {
  try {
    const userRole = localStorage.getItem('role');
          const headers = await matchHeaders(userRole);
          const response = await axios.get(api.listClubNames, {
            headers: {
              'Content-Type': 'application/json',
              apikey: headers.apikey,
              tenant: headers.appTenant,
              tenantA: headers.authTenant,
            }
          });

    const clubs = response.data.clubs;
    const clubCount = clubs.length;
    console.log(clubCount);
    setClubCount(clubCount);
  } catch (error) {
    console.error('Error fetching club list:', error);
  } finally {
    setLoadingClubs(false);    
}};

  const fetchUsers = async () => {
    try {
      const userRole = localStorage.getItem('role');
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.retrieveUsers, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });

      const allUsers = response.data;
      setUsers(allUsers);

      const aggregateData = aggregateUsersByMonth(allUsers);
      setChartData(aggregateData);
      
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setUsersIsLoading(false);
    }
  };

  const fetchTotalRoundUps = async () => {
    try {
      const userRole = localStorage.getItem('role');
      const headers = await matchHeaders(userRole);
      const response = await axios.get(api.totalRoundups, {
        headers: {
          'Content-Type': 'application/json',
          apikey: headers.apikey,
          tenant: headers.appTenant,
          tenantA: headers.authTenant,
        }
      });
      
      //const maturingAmount = response.data.summary.getMaturingDebits;   
      // Example usage:
      const transactionForChart = transformData(response.data.documents);
      setChartDataTransactions(transactionForChart);
      

      const totalAmount = response.data.documents.reduce((acc, curr) => {
        return acc + curr.totalDebitAmount;
      }, 0) / 100;
      

      const getCurrentMonth = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Pad the month with a leading zero if needed
        return `${year}-${month}`; // Format: YYYY-MM
      };
      
      const currentMonth = getCurrentMonth(); // Get the current month
      
      const monthAmount = response.data.documents
        .filter((doc) => doc.month === currentMonth) // Filter by the current month
        .reduce((acc, curr) => {
          return acc + curr.totalDebitAmount;
        }, 0) / 100; // Convert to dollars
      
      console.log(monthAmount); // This will give you the total for the current month

      

      const getPreviousMonth = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth()).toString().padStart(2, '0'); // Pad the month with a leading zero if needed
        return `${year}-${month}`; // Format: YYYY-MM
      };
      
      const previousMonth = getPreviousMonth(); // Get the current month
      
      const lastMonthAmount = response.data.documents
        .filter((doc) => doc.month === previousMonth) // Filter by the current month
        .reduce((acc, curr) => {
          return acc + curr.totalDebitAmount;
        }, 0) / 100; // Convert to dollars
      
      console.log(lastMonthAmount); // This will give you the total for the current month

      
      
      setTotalRoundUps(totalAmount); 
      setInMonthRoundUps(monthAmount);
      setLastMonthRoundUps(lastMonthAmount);
//      setTotalMaturing(maturingAmount);

    } catch (error) {
      console.error('Error fetching debits:', error);
    } finally {
      setRoundUpsIsLoading(false);
    }
    }
  

    const transformData = (data) => {
      const formattedData = [];
    
      data.forEach((item) => {
        // Check if this month already exists in the formattedData array
        let monthEntry = formattedData.find((entry) => entry.month === item.month);
    
        if (!monthEntry) {
          // If the month doesn't exist, create a new entry for this month
          monthEntry = { month: item.month };
          formattedData.push(monthEntry);
        }
    
        // Add or update the totalDebitAmount for the tenantName in the existing or new month entry
        monthEntry[item.tenantName] = item.totalDebitAmount / 100;
      });
    
      return formattedData;
    };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(users); // Convert JSON data to sheet format
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users'); // Append sheet to workbook
    XLSX.writeFile(workbook, 'UsersData.xlsx'); // Generate Excel file and download
  };

  const defaultShadow = '0 2px 14px 0 rgb(32 40 45 / 8%)';

  const aggregateUsersByMonth = (users) => {
    const usersByMonth = {};
  
    users.forEach((user) => {
      if (user._createdDate && user.tenant) {
        const createdDate = new Date(user._createdDate);
        const yearMonth = format(createdDate, 'yyyy-MM'); // Format to "YYYY-MM"
  
        if (!usersByMonth[yearMonth]) {
          usersByMonth[yearMonth] = {};
        }
  
        if (!usersByMonth[yearMonth][user.tenant]) {
          usersByMonth[yearMonth][user.tenant] = 0;
        }
  
        usersByMonth[yearMonth][user.tenant] += 1;
  
        // Accumulate total users across all tenants
        if (!usersByMonth[yearMonth]['All Tenants']) {
          usersByMonth[yearMonth]['All Tenants'] = 0;
        }
  
        usersByMonth[yearMonth]['All Tenants'] += 1;
      }
    });
  
    // Convert the grouped data into cumulative format for chart rendering
    const sortedMonths = Object.keys(usersByMonth).sort(); // Sort by month (keys)
    const cumulativeData = [];
  
    let cumulativeCounts = {}; // Holds the cumulative count for each tenant
  
    sortedMonths.forEach((month) => {
      let currentMonthData = { month };
  
      // Iterate over each tenant and accumulate the values
      for (const tenant in usersByMonth[month]) {
        if (!cumulativeCounts[tenant]) {
          cumulativeCounts[tenant] = 0;
        }
  
        // Add the value from the current month to the cumulative count
        cumulativeCounts[tenant] += usersByMonth[month][tenant];
  
        // Assign the cumulative count to the current month's data
        currentMonthData[tenant] = cumulativeCounts[tenant];
      }
  
      cumulativeData.push(currentMonthData);
    });
  
    return cumulativeData; // This will be used as chart data
  };  

  const dashboardCardsCounts = [
    { title: 'Total Users', value: users.length, icon: <FaUsers size={30}/>, color: '#058efc', isLoading: usersIsLoading },
    { title: 'Consented Users', value: consentedUsersCount, icon: <CheckCircleIcon  fontSize="large" />, color: '#733aea',isLoading: usersIsLoading },
    { title: 'Onboard Complete', value: onboardedUsersCount, icon: <PeopleIcon fontSize="large" />, color: '#fd9722',isLoading: usersIsLoading },
    { title: 'Active Users', value: activeUsers, icon: <PeopleIcon fontSize="large" />,color: '#f2426e', isLoading: usersIsLoading },
    { title: 'Paused Users', value: pausedUsersCount, icon: <PeopleIcon fontSize="large" />, color: '#058efc',isLoading: usersIsLoading },
    ...(userRole === 'ROUNDA' || userRole === 'ADMIN' ? [  { title: 'Clubs', value: clubCount, icon: <GroupIcon  fontSize="large" />,color: '#f2426e', isLoading: loadingClubs }] : []),
  ];

  const dashboardCardsValues = [
    { title: 'This Month RoundUps', value: formatCurrency(inMonthRoundUps), color: '#058efc',isLoading: roundUpsIsLoading },
    { title: 'Last Month RoundUps', value: formatCurrency(lastMonthRoundUps),  color: '#733aea',isLoading: roundUpsIsLoading },
    { title: 'Total RoundUps', value: formatCurrency(totalRoundUps),  color: '#fd9722',isLoading: roundUpsIsLoading }

    // { title: 'Total RoundUps', value: formatCurrency(totalRoundUps), icon: <AttachMoneyIcon fontSize="large" />, isLoading: usersIsLoading }
  ];

 
  return (
    <Container maxWidth="xxl">
      <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <MainNav userRole={userRole} />
        <Box
          sx={{
            padding: '20px',
            marginLeft: isMobile ? '0' : '300px', // 240px for sidebar width on larger screens
            marginTop: '50px',
            transition: 'margin-left 0.3s', // Smooth transition when screen size changes
          }}
        >
          {isDashboardLoading ? (
            // Skeleton Loader for Dashboard Cards
            <>
              <Grid2 container justifyContent="space-between">
                <Skeleton variant="text" width="200px" height="40px" />
                <Skeleton variant="circular" width={30} height={30} />
              </Grid2>
  
              <Grid2 container spacing={3} mb={6}>
                {Array.from(new Array(4)).map((_, index) => (
                  <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                      sx={{
                        width: 250,
                        height: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'left',
                        borderRadius: '10px',
                        borderColor: '#e3e3e3',
                      }}
                    >
                      <CardContent>
                        <Skeleton variant="rectangular" width={40} height={40} />
                        <Box mt={2}>
                          <Skeleton variant="text" width="60%" height={28} />
                          <Skeleton variant="text" width="40%" height={20} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid2>
                ))}
              </Grid2>
  
              <Grid2 container spacing={3} mb={6}>
                {Array.from(new Array(4)).map((_, index) => (
                  <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                      sx={{
                        width: 250,
                        height: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'left',
                        borderRadius: '10px',
                        borderColor: '#e3e3e3',
                      }}
                    >
                      <CardContent>
                        <Skeleton variant="rectangular" width={40} height={40} />
                        <Box mt={2}>
                          <Skeleton variant="text" width="60%" height={28} />
                          <Skeleton variant="text" width="40%" height={20} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid2>
                ))}
              </Grid2>
  
              {/* Skeleton Loader for Line Chart */}
              <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px' }}>
                <Skeleton variant="text" width="30%" height={40} />
                <Skeleton variant="rectangular" width="100%" height={400} />
              </Box>
  
              {userRole === 'ADMIN' && (
                <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px' }}>
                  <Skeleton variant="text" width="30%" height={40} />
                  <Skeleton variant="rectangular" width="100%" height={400} />
                </Box>
              )}
            </>
          ) : (
            <>
              <Grid2 container justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                  Dashboard
                </Typography>
                {alertCount ? (
                  <Badge badgeContent={alertCount} color="error">
                    <NotificationsIcon sx={{ fontSize: 30, cursor: 'pointer' }} />
                  </Badge>
                ) : null}
              </Grid2>
  
              {/* Grid for dashboard cards */}
              <Grid2 container spacing={3} mb={6}>
                {dashboardCardsCounts.map((card, index) => (
                  <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                      sx={{
                        width: 250,
                        height: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'left',
                        borderRadius: '10px',
                        borderColor: '#e3e3e3',
                        color: '#000',
                      }}
                    >
                      <CardContent sx={{ textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                        <Box fullWidth>
                          <Grid2 container>
                            <Box padding={2}>{card.icon}</Box>
                            <Grid2 item>
                              <Box>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                  {card.value}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                                  {card.title}
                                </Typography>
                              </Box>
                            </Grid2>
                          </Grid2>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid2>
                ))}
              </Grid2>
  
              <Grid2 container spacing={3} mb={6}>
                {dashboardCardsValues.map((card, index) => (
                  <Grid2 item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Card
                      sx={{
                        width: 250,
                        height: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'left',
                        alignItems: 'left',
                        borderRadius: '10px',
                        borderColor: '#e3e3e3',
                        color: '#000',
                      }}
                    >
                      <CardContent sx={{ textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}>
                        <Box fullWidth>
                          <Grid2 container>
                            <Grid2 item>
                              <Box marginLeft={2}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                  {card.value}
                                </Typography>
                              </Box>
                              <Box marginLeft={2}>
                                <Typography variant="subtitle1" sx={{ color: 'grey' }}>
                                  {card.title}
                                </Typography>
                              </Box>
                            </Grid2>
                          </Grid2>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid2>
                ))}
              </Grid2>
              {userRole === 'ADMIN' && (
                <>
                  {/* Admin-specific Line Charts */}
                  <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6">User Growth month on month - All Tenants</Typography>
                    </Box>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                        <XAxis dataKey="month" stroke="#888" />
                        <YAxis stroke="#888" />
                        <Tooltip />
                        <Legend align="right" verticalAlign="top" iconType="circle" />
                        <Line type="monotone" dataKey="All Tenants" stroke="#ff7300" strokeWidth={2} activeDot={{ r: 8 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>

                  <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6">User Growth month on month - By Tenant</Typography>
                    </Box>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                        <XAxis dataKey="month" stroke="#888" />
                        <YAxis stroke="#888" />
                        <Tooltip />
                        <Legend align="right" verticalAlign="top" iconType="circle" />
                        <Line type="monotone" dataKey="CENTS2BILLS-PROD" stroke="#8884d8" strokeWidth={2} />
                        <Line type="monotone" dataKey="ROUNDA-STAGE" stroke="#82ca9d" strokeWidth={2} />
                        <Line type="monotone" dataKey="POKITPAL-PROD" stroke="#ffc658" strokeWidth={2} />
                        <Line type="monotone" dataKey="BOOST-YOUR-SUPER-PROD" stroke="#ff7300" strokeWidth={2} />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>

                  {/* Monthly Direct Debits by Tenant */}
                  <Box mt={5} sx={{ padding: '20px', backgroundColor: '#fff', borderRadius: '16px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6">Monthly Direct Debits by Tenant</Typography>
                    </Box>
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={chartDataTransactions}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
                        <XAxis dataKey="month" stroke="#888" />
                        <YAxis stroke="#888" />
                        <Tooltip />
                        <Legend align="right" verticalAlign="top" iconType="circle" />
                        <Line type="monotone" dataKey="CENTS2BILLS-PROD" stroke="#8884d8" strokeWidth={2} />
                        <Line type="monotone" dataKey="ROUNDA-STAGE" stroke="#82ca9d" strokeWidth={2} />
                        <Line type="monotone" dataKey="POKITPAL-PROD" stroke="#ffc658" strokeWidth={2} />
                        <Line type="monotone" dataKey="BOOST-YOUR-SUPER-PROD" stroke="#ff7300" strokeWidth={2} />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </ThemeProvider>
    </Container>
  );
};


export default DashboardNew;